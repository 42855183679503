$(document).on('ready turbolinks:load', () => {
  $('input[type=radio].my-payment__credit-card__radio').change((e) => {
    const cardId = $(e.currentTarget).val();
    $.ajax({
      url: `/users/payments/change_default_card`,
      method: 'patch',
      dataType: 'json',
      data: { card_id: cardId },
      success: (data) => {
        $('#liveAlert .alert').text(data.notice);
        $('#liveAlert .alert')
          .removeClass('alert-danger')
          .addClass('alert-success');
        $('#liveAlert').toast('show');
      },
      error: (res) => {
        $('#liveAlert .alert').text(
          res.responseJSON.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $(
          `input[type=radio].my-payment__credit-card__radio[value=${res.responseJSON.default_card}]`,
        ).prop('checked', true);
        $('#liveAlert').toast('show');
      },
    });
  });
});
