const numberWithCommas = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const numberWithoutCommas = (x) => {
  const parts = x.toString().split(',');
  return parseFloat(parts.join(''));
};

$(document).on('ready turbolinks:load', () => {
  $('#form-verify').hide();
  $('#new-subscription-otp').hide();
  $('#new-subscription-otp a').removeAttr('href');
  $('#form-verify button').prop('disabled', true);

  const otpInputs = [
    '#otp-ist',
    '#otp-sec',
    '#otp-third',
    '#otp-fourth',
    '#otp-fifth',
    '#otp-sixth',
  ];
  let tryCount = 0;

  otpInputs.forEach((input, index) => {
    if (index < otpInputs.length - 1) {
      $(input).keyup((e) => {
        if (!index) {
          otpInputs.forEach((inp, inx) => {
            if (inx !== 0) $(inp).val('');
          });
        }

        if ($(input).val().length) {
          $(otpInputs[index + 1]).focus();
        }
        $('#user_plan_otp_code').val(
          `${$('#otp-ist').val()}${$('#otp-sec').val()}${$(
            '#otp-third',
          ).val()}${$('#otp-fourth').val()}${$('#otp-fifth').val()}${$(
            '#otp-sixth',
          ).val()}`,
        );

        if ($('#user_plan_otp_code').val().length === otpInputs.length) {
          $('#form-verify button').prop('disabled', false);
          $('#form-verify button').addClass('btn-shadow--golden');
          $('#form-verify button').removeClass('btn-shadow--gray');
        } else {
          $('#form-verify button').prop('disabled', true);
          $('#form-verify button').addClass('btn-shadow--gray');
          $('#form-verify button').removeClass('btn-shadow--golden');
        }
      });
    } else {
      $(input).keyup((e) => {
        $('#user_plan_otp_code').val(
          `${$('#otp-ist').val()}${$('#otp-sec').val()}${$(
            '#otp-third',
          ).val()}${$('#otp-fourth').val()}${$('#otp-fifth').val()}${$(
            '#otp-sixth',
          ).val()}`,
        );

        if ($('#user_plan_otp_code').val().length === otpInputs.length) {
          $('#form-verify button').prop('disabled', false);
          $('#form-verify button').addClass('btn-shadow--golden');
          $('#form-verify button').removeClass('btn-shadow--gray');
        } else {
          $('#form-verify button').prop('disabled', true);
          $('#form-verify button').addClass('btn-shadow--gray');
          $('#form-verify button').removeClass('btn-shadow--golden');
        }
      });
    }
  });

  $('#new-subscription-otp a').on('click', () => {
    $('#form-verify').hide();
    $('#new-subscription-otp').hide();
    $('#form-validate').show();
    $('#new-subscription-data').show();

    $(
      '.my-subscription__purchase-summary #coupon-value .my-subscription__current-value',
    ).html(0);
    $(
      '.my-subscription__purchase-summary #coupon-value .my-subscription__current-value',
    ).data('price', 0);

    let total = 0;
    const totalSummaryTable = $('.my-subscription__purchase-summary').length;
    $(
      '.my-subscription__purchase-summary div:not(#coupon-value) .summary-line__value span',
    ).each((i, obj) => {
      total += numberWithoutCommas($(obj).html());
    });
    $(
      '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
    ).html(`${numberWithCommas((total / totalSummaryTable).toFixed(2))}`);
    $(
      '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
    ).data('price', `${total / totalSummaryTable}`);
  });

  $('#form-validate button').on('click', () => {
    const valuesToSubmit = $('#new_user_plan').serialize();

    $('#form-validate button').prop('disabled', true);
    $('#form-validate button').addClass('btn-shadow--gray');
    $('#form-validate button').removeClass('btn-shadow--golden');

    $.ajax({
      url: `/users/subscriptions/submit_for_verification`,
      method: 'post',
      dataType: 'json',
      data: valuesToSubmit,
      success: (data) => {
        $('#liveAlert .alert').text(data.notice);
        $('#liveAlert .alert')
          .removeClass('alert-danger')
          .addClass('alert-success');
        $('#liveAlert').toast('show');
        $('#form-verify').show();
        $('#new-subscription-otp').show();
        $('#form-validate').hide();
        $('#new-subscription-data').hide();
        $(
          '.my-subscription__purchase-summary #coupon-value .my-subscription__current-value',
        ).html(
          `${numberWithCommas(
            (
              parseFloat(data.discount_amount) *
              parseFloat($('#current-currency-rate').html()) *
              -1
            ).toFixed(2),
          )}`,
        );
        $(
          '.my-subscription__purchase-summary #coupon-value .my-subscription__current-value',
        ).data(
          'price',
          `${(parseFloat(data.discount_amount) * -1).toFixed(2)}`,
        );

        let total = 0;
        const totalSummaryTable = $(
          '.my-subscription__purchase-summary',
        ).length;
        $(
          '.my-subscription__purchase-summary div:not(#coupon-value) .summary-line__value span',
        ).each((i, obj) => {
          total += numberWithoutCommas($(obj).html());
        });
        $(
          '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
        ).html(`${numberWithCommas((total / totalSummaryTable).toFixed(2))}`);
        $(
          '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
        ).data('price', `${total / totalSummaryTable}`);

        $('#form-validate button').prop('disabled', false);
        $('#form-validate button').addClass('btn-shadow--golden');
        $('#form-validate button').removeClass('btn-shadow--gray');
      },
      error: (res) => {
        $('#liveAlert .alert').text(
          res.responseJSON.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $('#liveAlert').toast('show');

        $('#form-validate button').prop('disabled', false);
        $('#form-validate button').addClass('btn-shadow--golden');
        $('#form-validate button').removeClass('btn-shadow--gray');
      },
    });
  });

  $('#form-verify button').on('click', () => {
    const valuesToSubmit = $('#new_user_plan').serialize();
    $('#form-verify button').prop('disabled', true);
    $('#form-verify button').addClass('btn-shadow--gray');
    $('#form-verify button').removeClass('btn-shadow--golden');

    $.ajax({
      url: `/users/subscriptions/verify`,
      method: 'post',
      dataType: 'json',
      data: valuesToSubmit,
      success: (data) => {
        $('#liveAlert .alert').text(data.notice);
        $('#liveAlert .alert')
          .removeClass('alert-danger')
          .addClass('alert-success');
        $('#liveAlert').toast('show');
        $('#new_user_plan').submit();
      },
      error: (res) => {
        $('#liveAlert .alert').text(
          res.responseJSON.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $('#liveAlert').toast('show');

        $('#form-verify button').prop('disabled', true);
        $('#form-verify button').removeClass('btn-shadow--golden');
        $('#form-verify button').addClass('btn-shadow--gray');

        tryCount += 1;

        $('#form-warning').text(
          `You only have ${5 - tryCount} time(s) left to try.`,
        );

        if (tryCount === 5) {
          // reset tryCount
          tryCount = 0;
          $('#form-warning').text('');

          $.ajax({
            url: `/users/subscriptions/cancel_verification`,
            method: 'post',
            dataType: 'json',
            success: () => {
              $('#new-subscription-otp a').trigger('click');
            },
            error: (error) => {
              $('#liveAlert .alert').text(
                error.responseJSON.error || "Oh dear. Something's wrong.",
              );
              $('#liveAlert .alert')
                .removeClass('alert-success')
                .addClass('alert-danger');
              $('#liveAlert').toast('show');
            },
          });
        }

        otpInputs.forEach((inp) => $(inp).val(''));
      },
    });
  });

  $('.my-subscription .my-subscription__plan-summary .field__radio__check').on(
    'change',
    (e) => {
      const planWrapper = $(e.target)
        .next('.field__radio__label')
        .children('.my-subscription__plan-summary__wrapper');

      $('.my-subscription__purchase-summary #plan-title').html(
        planWrapper.children('.my-subscription__plan-summary__name').html(),
      );
      $(
        '.my-subscription__purchase-summary #plan-value .my-subscription__current-value',
      ).html(
        `${numberWithCommas(
          (
            parseFloat(
              planWrapper
                .children('.my-subscription__plan-summary__total-price')
                .children('span')
                .data('price'),
            ) * parseFloat($('#current-currency-rate').html())
          ).toFixed(2),
        )}`,
      );
      $(
        '.my-subscription__purchase-summary #plan-value .my-subscription__current-value',
      ).data(
        'price',
        `${planWrapper
          .children('.my-subscription__plan-summary__total-price')
          .children('span')
          .data('price')}`,
      );
    },
  );

  $('input[name="user_plan[plan_id]"]').on('change', (e) => {
    let total = 0;
    const totalSummaryTable = $('.my-subscription__purchase-summary').length;
    $('.my-subscription__purchase-summary .summary-line__value span').each(
      (i, obj) => {
        total += numberWithoutCommas($(obj).html());
      },
    );
    $(
      '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
    ).html(`${numberWithCommas((total / totalSummaryTable).toFixed(2))}`);
    $(
      '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
    ).data('price', `${total / totalSummaryTable}`);
  });

  $('#currency-search').keyup((e) => {
    const value = $('#currency-search').val().toLowerCase();
    $('#currency-results .my-subscription__currency-modal__option').filter(
      (i, obj) =>
        $(obj).toggle(
          $(obj).data('currency').toLowerCase().indexOf(value) > -1,
        ),
    );
  });

  $('input[type=radio][name=currency]').change((e) => {
    $('#currency-confirm').attr('data-currency-value', $(e.target)[0].value);

    $('#currency-close').click(() => {
      $('#currency-search').val('');
      if ($(e.target)[0].id === $('#currency-input').text()) {
        $(e.target)[0].checked = true;
      } else if ($('#currency-input').text() === 'USD') {
        $('.my-subscription__currency-modal__option-check').filter(
          '[data-name="US Dollar"]',
        )[0].checked = true;
      }
      $('#currency-results .my-subscription__currency-modal__option').each(
        (i, obj) => {
          $(obj).show();
        },
      );
    });

    $(document).on('click', (event) => {
      if (!$(event.target).closest('#currency-main-modal').length) {
        $('#currency-search').val('');
        if ($(e.target)[0].id === $('#currency-input').text()) {
          $(e.target)[0].checked = true;
        } else if ($('#currency-input').text() === 'USD') {
          $('.my-subscription__currency-modal__option-check').filter(
            '[data-name="US Dollar"]',
          )[0].checked = true;
        }
      }
    });

    $('#currency-confirm').click(() => {
      $('#currency-search').val('');
      $('#currency-results .my-subscription__currency-modal__option').each(
        (i, obj) => {
          $(obj).show();
        },
      );

      $('#currency-input').html(
        $(e.target).next('.my-subscription__currency-modal__option-button')[0]
          .innerHTML,
      );
      $('#current-currency-name').html(`${$(e.target)[0].dataset.name}`);
      $('#current-currency-rate').html(`${$(e.target)[0].value}`);
      $('.my-subscription__current-currency').html(`${$(e.target)[0].id}`);
      $('.my-subscription__current-value').each((i, obj) => {
        if ($(obj).data('price'))
          $(obj).html(
            numberWithCommas(
              (
                parseFloat($(obj).data('price')) *
                parseFloat($(e.target)[0].value)
              ).toFixed(2),
            ),
          );
      });

      let total = 0;
      const totalSummaryTable = $('.my-subscription__purchase-summary').length;
      $('.my-subscription__purchase-summary .summary-line__value span').each(
        (i, obj) => {
          total += numberWithoutCommas($(obj).html());
        },
      );
      $(
        '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
      ).html(`${numberWithCommas((total / totalSummaryTable).toFixed(2))}`);
      $(
        '.my-subscription__purchase-summary #total-value .my-subscription__current-value',
      ).data('price', `${total / totalSummaryTable}`);
    });
  });

  $('.my-subscription__currency-modal__option').click((e) => {
    $('#current-currency-name').html(`${$(e.target)[0].dataset.name}`);
  });
});
