/* eslint-disable no-param-reassign */
import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';

const numberWithCommas = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

class CartController extends Controller {
  setPriceCurrency(event) {
    const { currencyValue } = event.target.dataset;

    let totalPrice = 0.0;

    $('.my-subscription__current-value').each((i, el) => {
      if ($(el).html()) {
        const price = numberWithCommas(
          (
            parseFloat(el.dataset.defaultValue) * parseFloat(currencyValue)
          ).toFixed(2),
        );

        el.textContent = price.replace(',', '');
      }
    });

    $('.cart__summary .my-subscription__current-value').each((i, e) => {
      totalPrice += parseFloat($(e).html());
    });

    $('.my-subscription__current-value-total').html(totalPrice);

    $('.my-subscription__current-value-total').html(totalPrice);
  }

  checkoutWithoutCard(event) {
    event.stopPropagation();
    $.ajax({
      url: '/users/cart/checkout',
      method: 'put',
      dataType: 'json',
      success: (data) => {
        $('#liveAlert .alert').text('Submit payment successfully!');
        $('#liveAlert .alert')
          .removeClass('alert-danger')
          .addClass('alert-success');
        $('#liveAlert').toast('show');
        setTimeout(() => {
          Turbolinks.visit('/users/courses');
        }, 1000);
      },
      error: (res) => {
        $('#liveAlert .alert').html(
          res.responseJSON.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $('#liveAlert').toast('show');
      },
    });
  }
}

export default CartController;
