import $ from 'jquery';
import { cycleCarousel, addClassPlaceholder } from '../common/helper.js';
import 'select2';
import 'select2/dist/css/select2.css';

$(document).on('ready turbolinks:load', () => {
  const states = [
    {
      id: 'gb',
      text: 'ENG',
    },
  ];

  function formatState(state) {
    if (!state.id) {
      return state.text;
    }
    const $state = $(
      `
        <span class="d-flex fw-bold align-items-center fs-small">
          <i class="flag-icon flag-icon-${state.id} me-2"></i>
          ${state.text}
        </span>
      `,
    );
    return $state;
  }

  $('.select2-states').select2({
    templateResult: formatState,
    templateSelection: formatState,
    data: states,
    minimumResultsForSearch: -1,
  });

  cycleCarousel('#carouselWall');
  addClassPlaceholder();
  cycleCarousel('#carouselCourseHome');
  cycleCarousel('#carouselCourseHomeMobile');

  $('a[disabled]').on('click', (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
  });
});

document.addEventListener('turbolinks:before-cache', () => {
  // removing the select2 from all selects
  const $select = $('select');
  if ($select.data('select2')) {
    $select.select2('destroy');
  }
});
