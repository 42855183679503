/* eslint-disable no-restricted-globals */
import { debounce } from 'lodash';

$(document).on('ready turbolinks:load', () => {
  $('.search-courses-discussion').keyup((e) => {
    if (
      e.key === 'Enter' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight'
    )
      return;
    searchCourse(e.currentTarget.value);
  });

  $('.discussions__students_card__item').on('click', (e) => {
    $('.discussions__students_card__item').removeClass('--active');
    const discussionId = e.currentTarget.id;
    $(`#${discussionId}`).addClass('--active');
  });

  $('.admin-tab').on('click', () => {
    $('.search_qa').addClass('d-none d-lg-none');
    $('#message-div')[0].scrollTop = $('#message-div')[0].scrollHeight;
  });

  $('.course-tab').on('click', () => {
    $('.search_qa').removeClass('d-lg-none');
  });

  document.getElementById('message_content')?.addEventListener(
    'keyup',
    function () {
      this.style.overflow = 'hidden';
      this.style.height = 0;
      this.style.height = `${this.scrollHeight}px`;
    },
    false,
  );

  $('#message_content').keypress((e) => {
    if (e.which === 13 && !e.shiftKey) {
      $('.subit_mesage').click();
      e.preventDefault();
      $('.chat-message').height = '3rem';
    }
  });
});

const searchCourse = debounce((keyword) => {
  ajaxSearchNameCourse(keyword);
}, 500);

const ajaxSearchNameCourse = (name) => {
  const url = $(location).attr('href');
  $.ajax({
    url: `${url}?search=${name}`,
    method: 'get',
    dataType: 'script',
  });
};
