$(document).on('ready turbolinks:load', () => {
  $('.has-invalid-input').on('input', (event) => {
    removeInvalidClass(event.target)
  });
});

const removeInvalidClass = (element) => {
  if(element.value.length !== 0) {
    $(element).removeClass('is-invalid');
  } else {
    $(element).addClass('is-invalid');
  }
}
