import { debounce } from 'lodash';

$(document).on('ready turbolinks:load', () => {
  cardProgrammeCourse();

  $('#select_price_course.modal').on('click', (e) => {
    const courseId = e.target.id.split('_').pop();

    if (e.target.id.includes('cart_item_is_livestream_false_')) {
      $(`#cart_item_is_livestream_false_${courseId}`).addClass(
        'field__radio--checked',
      );
      $(`#cart_item_is_livestream_true_${courseId}`).removeClass(
        'field__radio--checked',
      );
    } else if (e.target.id.includes('cart_item_is_livestream_true_')) {
      $(`#cart_item_is_livestream_true_${courseId}`).addClass(
        'field__radio--checked',
      );
      $(`#cart_item_is_livestream_false_${courseId}`).removeClass(
        'field__radio--checked',
      );
    }
  });

  $('#select_price_course.modal').on('click', '.button_buy-now', (event) => {
    event.stopPropagation();
    buyNowEvent(event);
  });

  $('.button_buy-now').on('click', (event) => {
    buyNowEvent(event);
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('.programme__button.--share').on('click', (event) => {
    $('#share-modal').modal('show');
  });

  $('#share-modal.modal').on('click', '.button-copy', (event) => {
    setTimeout(() => {
      $('#share-modal').modal('hide');
    }, 1000);
  });

  $('.btn-show-price').on('click', (event) => {
    event.stopPropagation();
  });

  const handleClickFilter = (categoriesSelected) => {
    $(document).on(
      'click',
      '.programme__button__category, .programme__filter__remove-icon',
      (e) => {
        addOrRemove(categoriesSelected, e.currentTarget.dataset.id);
        const path = window.location.href;

        if (categoriesSelected.length > 0) {
          const lastPath = `groupSelected=${groupSelected}&categories=${categoriesSelected.join(
            '+',
          )}`;

          if (window.location.href.split('?')?.pop() === 'free_courses=true') {
            window.location.href = `${path}&${lastPath}`;
          } else {
            window.location.href = `courses?${lastPath}`;
          }
        } else {
          window.location.href = path.includes('free_courses=true')
            ? '/courses?free_courses=true'
            : (window.location.href = '/courses');
        }
      },
    );
  };

  const addOrRemove = (array, value) => {
    const index = array.indexOf(value);

    if (index === -1) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }
  };

  const handleIconDropdown = (groupName) => {
    $('.programme__filter__category .fa-caret-up')
      .removeClass('fa-caret-up')
      .addClass('fa-caret-down');
    if ($('.programme__filter__options').is(':visible')) {
      $(`.programme__filter__category[data-name="${groupName}"] .fa-caret-down`)
        .removeClass('fa-caret-down')
        .addClass('fa-caret-up');
    } else {
      $(`.programme__filter__category[data-name="${groupName}"] .fa-caret-up`)
        .removeClass('fa-caret-up')
        .addClass('fa-caret-down');
    }
  };

  const getCategories = (groupName) => {
    $.ajax({
      url: `/courses/categories/${groupSelected}`,
      method: 'get',
      dataType: 'json',
      data: { categories_selected: categoriesSelected },
      success(data) {
        $('#list_categories').html('').append(data.result);
        $('.programme__filter__options').slideDown(300, () => {
          handleIconDropdown(groupName);
        });
      },
      error(error) {
        console.log('An error occurred', error);
      },
    });
  };

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const categories = searchParams.get('categories');
  let groupSelected;
  const categoriesSelected = categories ? categories.split(' ') : [];

  handleClickFilter(categoriesSelected);

  $('.programme__filter__category').on('click', (event) => {
    const target = event.currentTarget;
    const groupName = target.dataset.name;
    $('#group_name').text(groupName);
    if (groupSelected === target.dataset.id) {
      $('.programme__filter__options').slideToggle(300, () => {
        handleIconDropdown(groupName);
      });
    } else {
      groupSelected = target.dataset.id;
      getCategories(groupName);
    }
  });

  if (categoriesSelected.length > 0 && searchParams.get('groupSelected')) {
    const $selectedEle = $(
      `.programme__filter__category[data-id="${searchParams.get(
        'groupSelected',
      )}"]`,
    );
    $selectedEle.click();
    getCategories($selectedEle[0].dataset.name);
  }

  $('.lesson__card .collapse').on('show.bs.collapse', (e) => {
    $(e.target)
      .closest('.lesson__card')
      .find('.fa-caret-down')
      .removeClass('fa-caret-down')
      .addClass('fa-caret-up');
  });
  $('.lesson__card .collapse').on('hide.bs.collapse', (e) => {
    $(e.target)
      .closest('.lesson__card')
      .find('.fa-caret-up')
      .removeClass('fa-caret-up')
      .addClass('fa-caret-down');
  });

  $('.readmore__link').click((e) => {
    // record if our text is expanded
    const isExpanded = $(e.target).hasClass('--expand');

    // close all open paragraphs
    $('.readmore.--expand').removeClass('--expand');
    $('.readmore__link.--expand').removeClass('--expand');

    // if target wasn't expand, then expand it
    if (!isExpanded) {
      $(e.target).parent('.readmore').addClass('--expand');
      $(e.target).addClass('--expand');
    }
  });
  // remove readmore if description shorter.
  if (
    $('.readmore__description').height() <
    parseInt($('.readmore__description').css('max-height'), 10)
  ) {
    $('.readmore__link').addClass('d-none');
    $('.readmore').addClass('no-after');
  }

  $('.search-programme').keyup((e) => {
    if (
      e.key === 'Enter' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight'
    )
      return;
    searchProgramme(e.currentTarget.value, e.currentTarget.dataset.url);
  });

  const searchProgramme = debounce((keyword, path) => {
    ajaxSearchNameCourse(keyword, path);
  }, 500);

  const ajaxSearchNameCourse = (name, path) => {
    $.ajax({
      url: path,
      method: 'get',
      dataType: 'script',
      data: {
        search: name,
        categories,
      },
    });
  };

  // Student rate stars in a course
  $('#show-review-modal.modal').on('click', '.rating-star', (event) => {
    const stars = $(event.currentTarget).attr('data-stars');
    const starId = $(event.currentTarget).attr('data-star-id');

    for (let i = 1; i <= 5; i += 1) {
      if (i <= stars) {
        $(`#${starId}_${i}`)
          .removeClass('programme__rating__blank-star')
          .addClass('programme__rating__star');
      } else {
        $(`#${starId}_${i}`)
          .removeClass('programme__rating__star')
          .addClass('programme__rating__blank-star');
      }
    }

    $(`#${starId}_stars`).val(stars);
  });

  $('#show-review-modal.modal').on('click', '#close-modal-review', (event) => {
    $('#show-review-modal.modal').modal('hide');
  });
});

const cardProgrammeCourse = () => {
  $('.course-detail').on('click', (event) => {
    const target = event.currentTarget;
    const path = $(target).data('path');

    window.location.href = path;
  });

  $('.programme__button--cartss').on('click', (event) => {
    const target = event.currentTarget;
    const courseId = $(target).data('course-id');

    const isLivestreamFalse = $(`#cart_item_is_livestream_false_${courseId}`);
    const isLivestreamTrue = $(`#cart_item_is_livestream_true_${courseId}`);
    isLivestreamFalse.addClass('field__radio--checked');
    isLivestreamFalse.on('click', () => {
      isLivestreamFalse.addClass('field__radio--checked');
      isLivestreamTrue.removeClass('field__radio--checked');
    });

    isLivestreamTrue.on('click', () => {
      isLivestreamTrue.addClass('field__radio--checked');
      isLivestreamFalse.removeClass('field__radio--checked');
    });
  });

  $('.programme__button--cart').on('click', (event) => {
    event.stopPropagation();
    // handle add to cart
    const target = event.currentTarget;
    const courseId = $(target).data('course-id');
    const courseType = $(target).data('course-type');
    let isLivestream = false;
    if (courseType === 'livestream') {
      isLivestream = true;
    }
    $.ajax({
      url: `/users/cart/add_to_cart`,
      method: 'post',
      dataType: 'json',
      data: { course_id: courseId, is_livestream: isLivestream },
      success: (data) => {
        $('#liveAlert .alert').text('Add to cart successfully');
        $('#liveAlert .alert')
          .removeClass('alert-danger')
          .addClass('alert-success');
        $('.header__icon-cart--custom-total').text(data.total);
        $('#liveAlert').toast('show');
      },
      error: (res) => {
        $('#liveAlert .alert').text(
          res.responseJSON?.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $('#liveAlert').toast('show');
      },
    });
  });

  $('.add_to_cart_modal').on('click', (event) => {
    addToCartEvent(event);
  });

  $('#select_price_course.modal').on('click', '.add_to_cart_modal', (event) => {
    addToCartEvent(event);
  });

  $('.programme__button--folder').on('click', (event) => {
    addToFolderEvent(event);
  });
};

const addToCartEvent = (event) => {
  const target = event.currentTarget;
  const courseId = $(target).data('course-id');
  let isLivestream = false;
  const check = $(`#cart_item_is_livestream_true_${courseId}`).hasClass(
    'field__radio--checked',
  );
  if (check) {
    isLivestream = true;
  }

  $.ajax({
    url: `/users/cart/add_to_cart`,
    method: 'post',
    dataType: 'json',
    data: { course_id: courseId, is_livestream: isLivestream },
    success: (data) => {
      $('#liveAlert .alert').text('Add to cart successfully');
      $('#liveAlert .alert')
        .removeClass('alert-danger')
        .addClass('alert-success');
      $('.header__icon-cart--custom-total').text(data.total);
      $('#liveAlert').toast('show');
    },
    error: (res) => {
      $('#liveAlert .alert').text(
        res.responseJSON?.error || "Oh dear. Something's wrong.",
      );
      $('#liveAlert .alert')
        .removeClass('alert-success')
        .addClass('alert-danger');
      $('#liveAlert').toast('show');
    },
  });
};

const buyNowEvent = (event) => {
  const target = event.currentTarget;
  const courseId = $(target).data('course-id');
  const courseType = $(target).data('course-type');
  let isLivestream = false;
  const check = $(`#cart_item_is_livestream_true_${courseId}`).hasClass(
    'field__radio--checked',
  );
  if (check) {
    isLivestream = true;
  }
  if (courseType === 'livestream') {
    isLivestream = true;
  }

  $.ajax({
    url: `/users/cart/add_to_cart`,
    method: 'post',
    dataType: 'json',
    data: { course_id: courseId, is_livestream: isLivestream },
    success: (data) => {
      // eslint-disable-next-line no-undef
      Turbolinks.visit('/users/cart');
      $('#liveAlert .alert').text('Add to cart successfully');
      $('#liveAlert .alert')
        .removeClass('alert-danger')
        .addClass('alert-success');
      $('#liveAlert').toast('show');
    },
    error: (res) => {
      if (res.responseJSON.error === 'This course already in your cart') {
        // eslint-disable-next-line no-undef
        Turbolinks.visit('/users/cart');
      } else {
        $('#liveAlert .alert').text(
          res.responseJSON.error || "Oh dear. Something's wrong.",
        );
        $('#liveAlert .alert')
          .removeClass('alert-success')
          .addClass('alert-danger');
        $('#liveAlert').toast('show');
      }
    },
  });
};

const addToFolderEvent = (event) => {
  const target = event.currentTarget;
  const courseId = $(target).data('course-id');
  const courseType = $(target).data('course-type');
  let isLivestream = false;
  if (courseType === 'livestream') {
    isLivestream = true;
  }
  $.ajax({
    url: `/users/courses/add_to_folder`,
    method: 'post',
    dataType: 'json',
    data: { course_id: courseId, is_livestream: isLivestream },
    success: (data) => {
      $('#liveAlert .alert').text('Add to My Courses successfully');
      $('#liveAlert .alert')
        .removeClass('alert-danger')
        .addClass('alert-success');
      $('#liveAlert').toast('show');
    },
    error: (res) => {
      $('#liveAlert .alert').text(
        res.responseJSON?.error || "Oh dear. Something's wrong.",
      );
      $('#liveAlert .alert')
        .removeClass('alert-success')
        .addClass('alert-danger');
      $('#liveAlert').toast('show');
    },
  });
}

window.cardProgrammeCourse = cardProgrammeCourse;
