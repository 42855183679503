$(document).on('ready turbolinks:load', () => {
  $('[data-bs-toggle="tooltip"]').tooltip()

  $(".alert.alert-dismissible").delay(5000).slideUp(200, () => {
    $(this).alert('close');
  });

  $('.classes-detail__card-lesson .collapse').on('show.bs.collapse', (e) => {
    $(e.target).closest('.classes-detail__card-lesson').find('.fa-caret-down').removeClass('fa-caret-down').addClass('fa-caret-up')
  })

  $('.classes-detail__card-lesson .collapse').on('hide.bs.collapse', (e) => {
    $(e.target).closest('.classes-detail__card-lesson').find('.fa-caret-up').removeClass('fa-caret-up').addClass('fa-caret-down')
  })
})
