/* eslint-disable no-param-reassign */
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '@/channels';

import '@/controllers/application';
import '@/scss/application/index.scss';
import 'flag-icon-css/css/flag-icon.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'chartkick/chart.js';
import '@/controllers/users';

require('bootstrap');

Rails.start();
Turbolinks.start();
ActiveStorage.start();
require.context('@/images', true);

require('./pages/home');
require('./pages/registration');
require('./pages/courses');
require('./pages/payments');
require('./pages/subscription');
require('./users/my-courses');
require('./users/lesson');
require('./users/dashboard');
require('./users/profile');
require('./users/cart');
require('./videojs');
require('./confirm_modal');
require('./common/errors_input');
require('./common/user_timezone');
require('./pages/schedule');
require('./teacher/discussions');
require('./admin/discussions');
