import { Controller } from 'stimulus';

class ButtonController extends Controller {
  connect() {
    $('body').on('mousedown', 'button.btn-shadow', function() {
      $(this).addClass('active');
    })

    $('body').on('mouseup', 'button.btn-shadow', function() {
      let $this = $(this);
      setTimeout(function() {
        $this.removeClass('active');
      }, 200)
    })
  }
}

export default ButtonController;
