/* eslint-disable no-plusplus */
import { Controller } from 'stimulus';

const videoScroll = () => {
  if (document.querySelectorAll('video[autoplay]').length > 0) {
    const windowHeight = window.innerHeight;
    const videoEl = document.querySelectorAll('video[autoplay]');

    for (let i = 0; i < videoEl.length; i++) {
      const thisVideoEl = videoEl[i];
      const videoHeight = thisVideoEl.clientHeight;
      const videoClientRect = thisVideoEl.getBoundingClientRect().top;

      if (
        videoClientRect <= windowHeight - videoHeight * 0.5 &&
        videoClientRect >= 0 - videoHeight * 0.5
      ) {
        thisVideoEl.play();
      } else {
        thisVideoEl.pause();
      }
    }
  }
};
class PagesController extends Controller {
  connect() {
    document.querySelector('.toast-body')?.classList?.add('d-none');
    window.addEventListener('load', videoScroll);
    window.addEventListener('scroll', videoScroll);
  }

  controlVideo(event) {
    const videoEl = event.currentTarget;

    if (videoEl.paused === false) {
      videoEl.pause();
      videoEl.firstChild.nodeValue = 'Play';
    } else {
      videoEl.play();
      videoEl.firstChild.nodeValue = 'Pause';
    }
  }
}

export default PagesController;
