/* eslint-disable import/prefer-default-export */
import { cycleCarousel } from '../common/helper.js';

$(document).on('ready turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();

  $('#share-referral-code').on('click', async (event) => {
    const $btn = event.currentTarget;
    await copyToClipboard($btn.dataset.code);
  });

  $('#phone_number_contact_info').on('input', (e) => {
    if (e.target.value.length !== 0) {
      $('#phone_number_contact_info>.has-invalid-input').removeClass(
        'is-invalid',
      );
      $('#invalid_phone_message').addClass('d-none');
    } else {
      $('#phone_number_contact_info>.has-invalid-input').addClass('is-invalid');
      $('#invalid_phone_message').removeClass('d-none');
    }
  });

  $('#invalid_phone_message').css({
    width: '100%',
    'margin-top': '0.25rem',
    'font-size': '0.875em',
    color: '#dc3545',
  });

  cycleCarousel('#carouselUpcomingCourse');
});

export const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
};
