import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

const invalidClass = (inputElement) => {
  if (!inputElement.value) {
    inputElement.classList.add('is-invalid');
  } else {
    inputElement.classList.remove('is-invalid');
  }
};

const callApiSubmit = (event) => {
  const { url } = event.target.dataset;
  const form = document.forms.new_homework_submit;
  // const modal = document.getElementById('createHomeworkModal');

  Rails.ajax({
    url,
    type: 'POST',
    dataType: 'json',
    data: new FormData(form),
    success: (res) => {
      // TODO should check this one
      // document.getElementById('listDataHomeworks').innerHTML = res.html;
      // document.getElementById('pills-homework-tab').click();
      // modal.querySelector('.btn-close')?.click();
      Turbolinks.visit(window.location.href);
    },
  });
};

const callApiGetListHomework = (event) => {
  const { url } = event.currentTarget.dataset;
  const modal = document.getElementById('createHomeworkModal');

  Rails.ajax({
    url,
    type: 'GET',
    dataType: 'json',
    success: (res) => {
      document.getElementById('listDataHomeworks').innerHTML = res.html;
      modal?.querySelector('.btn-close')?.click();
    },
  });
};

const showContentModal = (event, modalId) => {
  const { url } = event.currentTarget.dataset;
  const modal = document.getElementById(modalId);

  Rails.ajax({
    url,
    type: 'GET',
    dataType: 'json',
    success: (res) => {
      modal.querySelector('.modal-body').innerHTML = res.html;
      modal.querySelector('.btn-close').click();
    },
  });
};

class HomeworkController extends Controller {
  submitHomework(event) {
    const modal = document.getElementById('createHomeworkModal');
    const form = document.forms.new_homework_submit;
    const nameInputEl = form['homework_submit[name]'];
    const descriptionInputEl = form['homework_submit[description]'];
    const fileInputEl = form['homework_submit[file]'];

    if (
      nameInputEl.value &&
      descriptionInputEl.value &&
      fileInputEl.files.length > 0
    ) {
      callApiSubmit(event);
    } else {
      invalidClass(nameInputEl);
      invalidClass(descriptionInputEl);

      const fileEl = modal.querySelector('.filepond');

      if (fileInputEl.files?.length === 0) {
        fileEl.classList.add('filepond--null');
      } else {
        fileEl.classList.remove('filepond--null');
      }
    }
  }

  showModalCreate(event) {
    const form = document.forms.new_homework_submit;
    form
      .querySelector('#homework_submit_homework_id')
      .setAttribute('value', event.target.dataset.homeworkId);
  }

  listHomework(event) {
    callApiGetListHomework(event);
  }

  showListHomeworkSubmits(event) {
    showContentModal(event, 'modalStudentsSubmitted');
  }

  showHomeworkSubmitDetail(event) {
    showContentModal(event, 'modalShowFileHomework');
  }

  showFileDetail(event) {
    const { url, fileType } = event.currentTarget.dataset;
    const modal = document.getElementById('modalShowFileHomework');
    const bodyEl = modal.querySelector('.modal-body');

    if (fileType.includes('/pdf') || fileType.includes('image/')) {
      const embedEl = `<embed src=${url} width="100%" height="1000px" ></embed> `;
      bodyEl.innerHTML = embedEl;
    } else {
      bodyEl.innerHTML = `<h5 id='unpreview'>Can not support preview this one, please click download to view this file</h5>`;
    }
  }
}

export default HomeworkController;
