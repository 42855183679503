import * as bootstrap from 'bootstrap';

export const cycleCarousel = (id) => {
  const carousels = document.querySelectorAll(id);
  carousels.forEach((carousel) => {
    const carouselInstance = bootstrap.Carousel.getOrCreateInstance(carousel);
    carouselInstance.cycle();
  });
}
export const addClassPlaceholder = () => {
  if ($('.prompt').length) {
    $('.prompt').each(function() {
      if ($(this).val() === "" || $(this).val() === null) {
        $(this).addClass('placeholder');
      } else {
        $(this).removeClass('placeholder');
      }
    });

    $('.prompt').on('change', function (e){
      if ($(this).val() === "" || $(this).val() === null) {
        $(this).addClass('placeholder');
      } else {
        $(this).removeClass('placeholder');
      }
    });
  }
}
