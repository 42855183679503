$(document).on('ready turbolinks:load', () => {
  if ($('#message-div').length > 0) {
    $('#message-div')[0].scrollTop = $('#message-div')[0].scrollHeight;
  }

  document.getElementById('message_content')?.addEventListener(
    'keyup',
    function () {
      this.style.overflow = 'hidden';
      this.style.height = 0;
      this.style.height = `${this.scrollHeight  }px`;
    },
    false,
  );

  $('#message_content').keypress((e) => {
    if (e.which === 13 && !e.shiftKey) {
      $('.subit_mesage').click();
      e.preventDefault();
      $('.chat-message').height = '3rem';
    }
  });
});
