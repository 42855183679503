/* eslint-disable no-undef */

$(document).on('ready turbolinks:load', () => {
  playerVideojs();

  let $videoSrc;
  $('.video-btn').click((e) => {
    $videoSrc = $(e.currentTarget).data('src');
  });
  $('#modal-schedule').on('shown.bs.modal', (e) => {
    const $videojs = $(e.target).find('.video-js')[0]
    // const player = videojs($videojs);
    $videojs.src = $videoSrc;
    $videojs.play();
  });
  $('#modal-schedule').on('hide.bs.modal', (e) => {
    const $videojs = $(e.target).find('.video-js')[0];
    $videojs.pause();
  });
})

const playerVideojs = () => {
  $('.video-js').on('play', (e) => {
    $('.video-js').removeClass('fit-cover').addClass('fit-unset')
    pauseAllVideoPLaying(e.currentTarget.id)
    const $playIcon = $(e.currentTarget).closest('.player-wrapper').find('.play-icon');
    $playIcon.addClass('d-none')
  })

  $('.video-js').on('pause', (e) => {
    const $playIcon = $(e.currentTarget).closest('.player-wrapper').find('.play-icon');
    $playIcon.removeClass('d-none')
  })

  const $playIcon = $('.player-wrapper .play-icon');
  $playIcon.on('click', (e) => {
    const $video = $(e.currentTarget).closest('.player-wrapper').find('.video-js')[0];
    $video.play();
  })
}

const pauseAllVideoPLaying = (_id) => {
  $('.video-js').each((i, obj) => {
    if (obj.id !== _id) {

      const player = $(`.video-js#${obj.id}`)[0];
      player.pause();
    }
  })
}
