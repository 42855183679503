$(document).on('ready turbolinks:load', () => {
  $('#showAnswerModal.modal').on('click', '.custom-cancel-btn', () => {
    $('#showAnswerModal.modal').modal('hide');
  });

  $('.btn_detail_student_answer_fill_blank').on('click', (event) => {
    $(event.target.dataset.target).modal('show');
  });

  $('.modal').on('click', '.custom-cancel-btn', () => {
    $('.modal').modal('hide');
  });
});
