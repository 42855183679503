/* eslint-disable no-param-reassign */
import * as bootstrap from 'bootstrap';

$(document).on('ready turbolinks:load', () => {
  if ($('#carouselCourse').length !== 0) {
    const carouselCourse = document.querySelector('#carouselCourse');
    const carouselCourseInstance = bootstrap.Carousel.getOrCreateInstance(
      carouselCourse,
      { interval: 10000, wrap: false, pause: false },
    );
    carouselCourseInstance.cycle();

    $("*[id^='video-intro-']").on('play', () => {
      carouselCourseInstance.pause();
    });
    $("*[id^='video-intro-']").on('ended', () => {
      carouselCourseInstance.next();
      carouselCourseInstance.cycle();
    });

    $('#carouselCourse').on('slide.bs.carousel', () => {
      const videoIDs = document.querySelectorAll("*[id^='video-intro-']");
      videoIDs.forEach((videoId) => {
        videoId.currentTime = 0;
        videoId.pause();
      });
    });
  }
});
