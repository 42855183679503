import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

const showToast = (message, type) => {
  $('#liveAlert .alert').html(message);

  if (type === 'success') {
    $('#liveAlert .alert').removeClass('alert-danger').addClass('alert-success');
  }
  else {
    $('#liveAlert .alert').removeClass('alert-success').addClass('alert-danger');
  }

  $('#liveAlert').toast('show');
}
class ProfileController extends Controller {
  static targets = ['promoCode', 'promoInput', 'promoButton'];

  setValuePromoteCode(event) {
    const el = event.currentTarget;
    this.promoteCodeTarget.href = `${el.dataset.url}?code=${el.value}`;
  }

  changeLoadingState() {
    if (this.promoButtonTarget.classList.contains('loading')) {
      this.promoButtonTarget.innerHTML = 'Apply';
      this.promoButtonTarget.classList.remove('loading');
    }
    else {
      this.promoButtonTarget.innerHTML = 'Applying...';
      this.promoButtonTarget.classList.add('loading');
    }
  }

  applyPromotion() {
    this.changeLoadingState();

    Rails.ajax({
      url: `/users/profile/apply_promotion?code=${this.promoInputTarget.value}`,
      type: 'POST',
      success: (res) => {
        this.promoInputTarget.value = '';
        showToast(res.message, 'success');
        this.changeLoadingState();
      },
      error: (err) => {
        showToast(err.message, 'error');
        this.changeLoadingState();
      }
    })
  }
}

export default ProfileController;
