import { Controller } from 'stimulus';

class HeaderController extends Controller {
  mouseOver(event) {
    const el = event.currentTarget;
    const ulEl = el.querySelector('ul');
    ulEl.classList.remove('d-none');
    ulEl.classList.add('d-block');
    const menuEls = document.querySelectorAll('.dropdown-submenu');
    Array.from(menuEls).forEach((e) => {
      const menuEl = e;
      menuEl.style.minHeight = '115px';
    });
  }

  mouseOut(event) {
    const el = event.currentTarget;
    const ulEl = el.querySelector('ul');
    ulEl.classList.remove('d-block');
    ulEl.classList.add('d-none');
  }
}

export default HeaderController;
