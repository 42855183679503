/* eslint-disable camelcase */
import consumer from './consumer';

document.addEventListener('turbolinks:load', () => {
  const messageDiv = $('#message-div');

  if (messageDiv.length) {
    const discussionGid = messageDiv.data('discussion-gid');

    create_custommer(discussionGid);
  }
});

window.create_custommer = (discussionGid) => {
  consumer.subscriptions.create(
    { channel: 'MessageChannel', discussion_gid: discussionGid },
    {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Channel Connected!!!');
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        const html_end = `
                          <div class="w-75 float-end">
                            <div
                              class="mb-3 message-box float-end message-box__bg-end"
                              key=${data.id}
                            >
                              <div class="d-flex p-2 text-white">
                                <img src=${data.avatar} class="avatar mt-2" />
                                <div>
                                  <p class="text-primary text-orange mb-0">${data.name}</p>
                                  <p class="m-0">${data.content}</p>
                                  <p class="m-0 float-end message-box__time">${data.time}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                       `;
        const html_start = `
                            <div class="w-75 float-start">
                              <div
                                class="mb-3 message-box float-start message-box__bg-start"
                                key=${data.id}
                              >
                                <div class="d-flex p-2 text-white">
                                  <img src=${data.avatar} class="avatar mt-2" />
                                  <div>
                                    <p class="text-primary text-orange mb-0">${data.name}</p>
                                    <p class="m-0">${data.content}</p>
                                    <p class="m-0 float-end message-box__time">${data.time}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          `;
        if ($('#message-div').data('current-user-id') === data.user_id) {
          $('#message-div').append(html_end);
          $('.noti_icon').addClass('d-none');
          $(
            `.student-${
              data.discussion_id
                ? data.discussion_id
                : data.lesson_discussion_id
            }`,
          ).addClass('d-none');
          $(`[key=${data.id}]`).each((index, element) => {
            if (index !== 0) {
              element.remove();
            }
          });
        } else {
          $('#message-div').append(html_start);
          $('.noti_icon').removeClass('d-none');
          $(
            `.student-${
              data.discussion_id
                ? data.discussion_id
                : data.lesson_discussion_id
            }`,
          ).removeClass('d-none');
          $(`[key=${data.id}]`).each((index, element) => {
            if (index !== 0) {
              element.remove();
            }
          });
        }
        $('.chat-message').val('');
        $('.chat-message').height = '3rem';
        $('#message-div')[0].scrollTop = $('#message-div')[0].scrollHeight;
      },
    },
  );
};
