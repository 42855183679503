$(document).on('ready turbolinks:load', () => {
  $(window).resize(() => {
    const browserWidth = $(window).innerWidth();
    let bsColumn = 'col-3';
    function resizeSlider(numColumns) {
      if(12 % numColumns !== 0) {
        bsColumn = 'col-3'
      } else {
        bsColumn = `col-${ 12 / numColumns}`;
      }

      // Upwrap the slide images from their containing divs
      $(".my-courses__carousel .programme__container").unwrap().unwrap().unwrap();;

      // Wrap every card in the appropriate bootstrap column
      $(".my-courses__carousel .programme__container").wrap(`<div class='${bsColumn} slide-wrapper'></div>`);

      const slideWrappers = $(".slide-wrapper");
      let activeItem = '';
      // Wrap every 3 cards in an item class, forming 1 whole slide
      for (let i = 0; i < slideWrappers.length; i += numColumns) {
        if (i === 0) {
          activeItem = 'active';
        } else {
          activeItem = '';
        }

        slideWrappers.slice(i, i + numColumns).wrapAll(`<div class='carousel-item ${activeItem}'><div class='row'></div></div>`);
      }
    }

    // if we're on a large desktop, organize the slides in 4 columns
    switch (true) {
      case (browserWidth > 1399):
        resizeSlider(4);
        break;
      case (browserWidth > 992):
        resizeSlider(3);
        break;
      case (browserWidth > 768):
        resizeSlider(2);
        break;
      default:
        resizeSlider(1);
    }
  }).resize();
})
