import { Controller } from 'stimulus';
import * as FilePond from 'filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

FilePond.registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

const baseOptions = {
  storeAsFile: true,
  maxFileSize: '15MB',
};

const avatarExtraOptions = {
  imagePreviewHeight: 200,
  imageCropAspectRatio: '1:1',
  imageResizeTargetWidth: 200,
  imageResizeTargetHeight: 200,
  styleLoadIndicatorPosition: 'center bottom',
  styleButtonRemoveItemPosition: 'center bottom',
  stylePanelLayout: 'compact circle',
  labelIdle: 'Drag & drop here',
};

const generateDestroyedAttachmentArrayInputName = (name) => {
  const baseName = name.slice(0, name.length - 2);
  const insertPosition = baseName.lastIndexOf('[');
  return `${baseName.slice(0, insertPosition + 1)}destroyed_${baseName.slice(
    insertPosition + 1,
  )}[]`;
};

class FilepondController extends Controller {
  static targets = ['input'];

  connect() {
    this.setupFilePond(this.inputTarget);
  }

  inputTargetConnected(inputTarget) {
    this.setupFilePond(inputTarget);
  }

  setupFilePond(inputTarget) {
    let options = { ...baseOptions };
    const {
      dataset: { styleLayout, files },
    } = inputTarget;

    if (styleLayout === 'avatar') {
      options = { ...options, ...avatarExtraOptions };
    }

    if (files) {
      options = { ...options, files: JSON.parse(files) };
    }

    // After loading existed files from server,
    // Filepond add inputs with value as existed file path,
    // We must remove these input params to prevent re-submiting existed files when having nochanges
    const removeInputFromExistedFiles = (rootInput, instance) => {
      const inputSelector = `.filepond--data input[name='${rootInput.getAttribute(
        'name',
      )}'][type='hidden']`;
      const inputElements = instance.element.querySelectorAll(inputSelector);
      inputElements.forEach((inputElement) => {
        inputElement.remove();
      });
    };

    const instance = FilePond.create(inputTarget, {
      ...options,
      oninit: () => {
        removeInputFromExistedFiles(inputTarget, instance);
      },
      onaddfile: () => {
        removeInputFromExistedFiles(inputTarget, instance);
      },
      onremovefile: (error, file) => {
        if (error) {
          console.error(`[filepond] ${error}`);
          return;
        }

        // In case of multiple file uploadings,
        // After removing a file, we need to store removed files's id to form data
        if (instance.allowMultiple) {
          const { attachment_id: attachmentId } = file.getMetadata();
          const filepondDataElement =
            instance.element.querySelector('.filepond--data');

          if (attachmentId) {
            const inputName = inputTarget.getAttribute('name');
            const pureInputName =
              generateDestroyedAttachmentArrayInputName(inputName);

            const inputElement = document.createElement('input');
            inputElement.setAttribute('type', 'hidden');
            inputElement.setAttribute('name', pureInputName);
            inputElement.setAttribute('value', attachmentId);

            filepondDataElement.appendChild(inputElement);
          }
        }
      },
    });
  }
}

export default FilepondController;
