import { Controller } from 'stimulus';

class HomeController extends Controller {
  connect() {
    console.log(
      'Log from app/javascripts/controllers/applications/pages/index.js',
    );
  }
}

export default HomeController;
